@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-font-smoothing: antialiased;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #555;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
html {
  scroll-behavior: smooth;
}
/* #icon {
  width: 130px;
  height: 130px;
  object-fit: scale-down;
  object-position: center;
} */
/* @media screen and (max-width: 768px) {
  #icon {
    width: 120px;
    height: 120px;
    object-fit: scale-down;
    object-position: center;
  }
} */
#iconn {
  max-width: 181px;
  max-height: 181px;
}
/* @media screen and (max-width: 2560px) {
  #contents-image {
    width: 212px;
    height: 240px;
  }
  @media screen and (max-width: 768px) {
    #contents-image {
      width: 128px;
      height: 100%;
    }
  }
} */
li {
  margin-left: 30px;
}
p {
  margin-top: 12px;
}

.content-detail li {
    @apply list-disc;
}
.content-detail ul {
    @apply list-disc py-2 pl-6;
}